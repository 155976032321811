@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

*,
::after,
::before {
  box-sizing: border-box;
  font-family: "Open Sans";
}


html,
body {
  background-color: #FFF;
}

.shadow-focus-meeting-form {
  box-shadow: rgba(102, 102, 102, 0.3) 0px 1px;
}

.focus\:shadow-focus-meeting-form:focus {
  box-shadow: rgba(102, 102, 102) 0px 2px;
}

.shadow-radio-meeting-form {
  box-shadow: rgba(102, 102, 102, 0.6) 0px 0px 0px 1px inset;
}

.react-international-phone-input-container .meeting-form {
  height: 100%;
}

.react-international-phone-input-container.meeting-form .react-international-phone-country-selector-button {
  border-radius: 0px !important;
  margin-right: 20px !important;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: none;
  opacity: 1;
  padding-block: 0px 15px !important;
  box-shadow: rgba(102, 102, 102, 0.3) 0px 1px;
  height: 100%;
}

.react-international-phone-input-container.meeting-form .react-international-phone-country-selector-button:focus {
  box-shadow: rgba(102, 102, 102) 0px 2px;
}

.react-international-phone-input-container.meeting-form .react-international-phone-input {
  border: none !important;
  box-shadow: rgba(102, 102, 102, 0.3) 0px 1px;
  width: 100%;
  background: transparent !important;
  font-size: 30px !important;
  padding-block: 0px 15px !important;
  color: #000000 !important;
  height: 100% !important;
}

.react-international-phone-input-container.meeting-form .react-international-phone-input:focus {
  box-shadow: rgba(102, 102, 102) 0px 2px;
}

.meeting-form .react-international-phone-flag-emoji {
  width: 30px !important;
  height: 30px !important;
  box-sizing: border-box;
  object-fit: cover;
}

.meeting-form .react-international-phone-country-selector-button__flag-emoji {
  margin: 0px 6px !important;
}

.meeting-form .react-international-phone-country-selector-dropdown {
  pointer-events: auto !important;
  z-index: 50 !important;
  list-style: none;
  padding: 0;
  inset-block-start: -16px !important;
  box-shadow: none !important;
  background-color: white;
  width: 450px !important;
  max-height: 300px !important;
  overflow-y: scroll;
  border-radius: 8px !important;
  border: 2px solid #66666699 !important;
}

.meeting-form .react-international-phone-country-selector-dropdown__list-item {
  height: 44px;
  max-width: 100%;
  width: auto !important;
  margin-inline: 16px;
  margin-block-end: 4px;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(102, 102, 102, 0.1);
  box-shadow: rgba(102, 102, 102, 0.6) 0px 0px 0px 1px inset;
  color: rgb(102, 102, 102);
  outline: 0px;
  padding-block: 4px;
  transition-duration: 0.1s;
  transition-property: color;
  transition-timing-function: ease-out;
  width: 100%;
  word-break: break-word;
  cursor: pointer;
  opacity: 1;
  padding: 0px 8px !important;
}

.meeting-form .react-international-phone-country-selector-dropdown__list-item-country-name {
  color: rgb(102, 102, 102) !important;
  font-size: 20px !important;
  line-height: 28px !important;
  font-family: 'Avenir Next';
}

.meeting-form .react-international-phone-country-selector-dropdown__list-item-dial-code {
  color: rgb(102, 102, 102) !important;
  font-size: 20px !important;
  line-height: 28px !important;
  margin-left: 20px;
}

.shadow-focus {
  box-shadow: rgba(230, 230, 230, 0.3) 0px 1px;
}

.focus\:shadow-focus:focus {
  box-shadow: rgba(230, 230, 230) 0px 2px;
}

.shadow-radio {
  box-shadow: rgba(230, 230, 230, 0.6) 0px 0px 0px 1px inset;
}

.react-international-phone-input-container {
  height: 100%;
}

.react-international-phone-input-container .react-international-phone-country-selector-button {
  border-radius: 0px !important;
  margin-right: 20px !important;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: none;
  opacity: 1;
  padding-block: 0px 15px !important;
  box-shadow: rgba(230, 230, 230, 0.3) 0px 1px;
  height: 100%;
  background: transparent !important;
}

.react-international-phone-input-container .react-international-phone-country-selector-button:focus {
  box-shadow: rgba(230, 230, 230) 0px 2px;
}

.react-international-phone-input-container .react-international-phone-input {
  border: none !important;
  box-shadow: rgba(230, 230, 230, 0.3) 0px 1px;
  width: 100%;
  background: transparent !important;
  font-size: 30px !important;
  padding-block: 0px 15px !important;
  color: #e6e6e6 !important;
  height: 100% !important;
}

.react-international-phone-input-container .react-international-phone-input:focus {
  box-shadow: rgba(230, 230, 230) 0px 2px;
}

.react-international-phone-flag-emoji {
  width: 30px !important;
  height: 30px !important;
  box-sizing: border-box;
  object-fit: cover;
}

.react-international-phone-country-selector-button__flag-emoji {
  margin: 0px 6px !important;
}

.react-international-phone-country-selector-dropdown {
  pointer-events: auto !important;
  z-index: 50 !important;
  list-style: none;
  padding: 0;
  inset-block-start: -16px !important;
  box-shadow: none !important;
  background-color: black !important;
  width: 450px !important;
  max-height: 300px !important;
  overflow-y: scroll;
  border-radius: 8px !important;
  border: 2px solid #e6e6e699 !important;
}

.react-international-phone-country-selector-dropdown__list-item:hover {
  background-color: #FFFFFF4d !important;
  border: 1px solid #e6e6e6 !important;
}

.react-international-phone-country-selector-dropdown__list-item {
  height: 44px;
  max-width: 100%;
  width: auto !important;
  margin-inline: 16px;
  margin-block-end: 4px;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: #e6e6e61a !important;
  box-shadow: rgba(102, 102, 102, 0.6) 0px 0px 0px 1px inset;
  color: #e6e6e6 !important;
  outline: 0px;
  padding-block: 4px;
  transition-duration: 0.1s;
  transition-property: color;
  transition-timing-function: ease-out;
  width: 100%;
  word-break: break-word;
  cursor: pointer;
  opacity: 1;
  padding: 0px 8px !important;
}

.react-international-phone-country-selector-dropdown__list-item-country-name {
  color: #e6e6e6 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  font-family: 'Avenir Next';
}

.react-international-phone-country-selector-dropdown__list-item-dial-code {
  color: #e6e6e6 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  margin-left: 20px;
}

@media (max-width : 425px) {
  .react-international-phone-input-container .react-international-phone-input {
    font-size: 20px !important;
  }

  .react-international-phone-flag-emoji {
    width: 20px !important;
    height: 20px !important;
  }

  .react-international-phone-country-selector-dropdown,
  .meeting-form .react-international-phone-country-selector-dropdown {
    width: 250px !important;
    max-height: 200px !important;
  }

  .react-international-phone-country-selector-dropdown__list-item-dial-code,
  .meeting-form .react-international-phone-country-selector-dropdown__list-item-dial-code {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-left: 0px !important;
  }

  .react-international-phone-country-selector-dropdown__list-item-country-name,
  .meeting-form .react-international-phone-country-selector-dropdown__list-item-country-name {
    font-size: 14px !important;
    line-height: 20px !important;
  }

}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* Remove default focus style */
}